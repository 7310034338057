import { Controller } from "@hotwired/stimulus"
import * as dateUtils from "../packs/date_utils"
import autoComplete from "@tarekraafat/autocomplete.js"
import htmlToElement from "../utilities/html_to_element"
import { exclamationCircleSolid, xCircleSolid } from "../utilities/heroicons"
import { loadingItemSkeleton } from "../utilities/loading_skeleton"
import getMetaValue from "../utilities/get_meta_value"

export default class extends Controller {
  static targets = [
    "startAtDate", "startAtTime", "endAtDate", "endAtTime",
    "product", "productPicture", "productName", "availableList",
    "priceReminder", "initialItemList", "orderItemsList", "priceSuggestion",
    "nextCollapseButton"
  ]

  static values = {
    rentalId: String,
    itemsUrl: String,
    bookedIds: Array,
    bookedItemGroupIds: Array
  }

  connect() {
    const productInput = this.productTarget
    const controllerDiv = this

    $("#productsSearchModal").on("shown.bs.modal", () => {
      $("#products-search").trigger("focus")
      $("#products-search").val(controllerDiv.productTarget.value)
      controllerDiv.productSearch.start(controllerDiv.productTarget.value)
    })

    $("#productsSearchModal").on("hide.bs.modal", () => {
      $("#products-search").val("")
    })

    $("#start_at_date").on("change", function(){
      dateUtils.adjustStartLimit($(this), $("#end_at_date"), 0);
      controllerDiv.clearList()

      if ($(this).val() != "") {
        controllerDiv.startAtDateTarget.closest(".form-group").classList.remove("field_with_errors")
      }
    })

    $("#end_at_date").on("change", function(){
      dateUtils.adjustFinishLimit($("#start_at_date"), $(this), 0);
      controllerDiv.clearList()

      if ($(this).val() != "") {
        controllerDiv.endAtDateTarget.closest(".form-group").classList.remove("field_with_errors")
      }
    })

    $(productInput).on("change", function() {
      if ($(this).val() != "") {
        controllerDiv.closest(".form-group").classList.remove("field_with_errors")
      }
    })

    this.productAutocomplete()
  }

  productAutocomplete() {
    this.productSearch = new autoComplete({
      placeHolder: "Cari nama produk",
      selector: "#products-search",
      wrapper: false,
      debounce: 200,
      data: {
        src: async (phrase) => {
          try {
            const url = `/rentals/${this.rentalIdValue}/products_autocomplete?phrase=${phrase}&no_items=true&format=json&renting_only=true`
            const source = await fetch(url)
            const data = await source.json()

            return data.results
          } catch(error) {
            return []
          }
        },
        keys: ["caption"]
      },
      resultsList: {
        destination: "#searchResult",
        position: "afterbegin",
        class: "list-group list-group-flush tw-shadow tw-rounded-0",
        noResults: true,
        maxResults: 20,
        element: (list, data) => {
          if (data.results.length == 0) {
            const info = `
              <div class="list-group-item tw-border-b-0 tw-text-gray-700 tw-p-4 tw-py-8 tw-justify-center tw-flex tw-items-center">
                ${xCircleSolid("tw-h-5 tw-w-5 tw-mr-2")}
                <span>
                  Tidak ada hasil untuk <span class="tw-font-semibold">${data.query}</span>
                </span>
              </div>
            `
            list.classList.remove("tw-shadow")
            list.prepend(htmlToElement(info));
          }
        }
      },
      resultItem: {
        class: "list-group-item list-group-item-action tw-p-4 tw-border-b",
        highlight: {
            render: true
        },
        element: (item, data) => {
          item.innerHTML = `
            <div class="tw-flex tw-items-start">
              <div class="tw-mr-4">
                <div class="tw-h-auto tw-w-[60px] lg:tw-w-[80px]">
                  <img src="${data.value.picture_url}" class="tw-w-full tw-rounded"/>
                </div>
              </div>
              <div class="tw-space-y-1 lg:tw-space-y-2">
                <div class="tw-text-gray-700 tw-font-medium">${data.match}</div>
                <div class="">${data.value.type}</div>
              </div>
            </div>
          `
        }
      },
      events: {
        input: {
          selection: (event) => {
            const selection = event.detail.selection.value
            const productID = selection.id
            const pictureUrl = selection.picture_url
            const productName = selection.caption

            this.productTarget.value = productName
            this.productIdValue = productID
            this.clearList()
            this.displayPicture(pictureUrl)
            this.displayProductName(productName)
            $("#productsSearchModal").modal("hide")
          }
        }
      }
    })
  }

  clearSearch(e) {
    e.target.value = ''
  }

  searchUnits(e) {
    e.preventDefault()

    const listElement = this.availableListTarget
    const itemsUrl = this.itemsUrlValue
    const productId = this.productIdValue

    const startAtDate = this.startAtDateTarget.value
    const startAtTime = this.startAtTimeTarget.value
    const startAtDateTime = encodeURIComponent(`${startAtDate} ${startAtTime}:00`)

    const endAtDate = this.endAtDateTarget.value
    const endAtTime = this.endAtTimeTarget.value
    const endAtDateTime = encodeURIComponent(`${endAtDate} ${endAtTime}:59`)

    if (startAtDate && endAtDate && productId) {
      const compareDateTime = dateUtils.startTimeLessThanEndTime(startAtDateTime,endAtDateTime)

      if (compareDateTime) {
        listElement.innerHTML = loadingItemSkeleton()

        this.startAtTimeTarget.classList.remove("is-invalid")
        this.endAtTimeTarget.classList.remove("is-invalid")
        this.startAtDateTarget.classList.remove("is-invalid")
        this.endAtDateTarget.classList.remove("is-invalid")
        this.productTarget.classList.remove("is-invalid")

        const options = {
          method: "POST",
          credentials: "same-origin",
          headers: {
            "X-CSRF-Token": getMetaValue("csrf-token"),
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            product_id: productId,
            start: startAtDateTime,
            finish: endAtDateTime,
            booked_ids: this.bookedIdsValue,
            booked_item_group_ids: this.bookedItemGroupIdsValue
          })
        }

        setTimeout(() => {
          fetch(itemsUrl, options)
            .then(response => response.text())
            .then(html => {
              listElement.classList.remove("d-none")
              listElement.innerHTML = html
            })
        }, 200);
      } else {
        listElement.classList.remove("d-none")
        listElement.innerHTML = this.errorMessage(
          "Waktu mulai tidak boleh lebih dari waktu selesai."
        )

        this.startAtTimeTarget.classList.add("is-invalid")
        this.endAtTimeTarget.classList.add("is-invalid")
      }
    } else {
      listElement.classList.remove("d-none")
      listElement.innerHTML = this.errorMessage(
        "Pastikan tanggal mulai, selesai, dan produk sudah terisi."
      )

      if (startAtDate) {
        this.startAtDateTarget.closest(".form-group").classList.remove("field_with_errors")
      } else {
        this.startAtDateTarget.closest(".form-group").classList.add("field_with_errors")
      }

      if (startAtTime) {
        this.startAtTimeTarget.closest(".form-group").classList.remove("field_with_errors")
      } else {
        this.startAtTimeTarget.closest(".form-group").classList.add("field_with_errors")
      }

      if (endAtDate) {
        this.endAtDateTarget.closest(".form-group").classList.remove("field_with_errors")
      } else {
        this.endAtDateTarget.closest(".form-group").classList.add("field_with_errors")
      }

      if (endAtTime) {
        this.endAtTimeTarget.closest(".form-group").classList.remove("field_with_errors")
      } else {
        this.endAtTimeTarget.closest(".form-group").classList.add("field_with_errors")
      }

      if (productId) {
        this.productTarget.closest(".form-group").classList.remove("field_with_errors")
      } else {
        this.productTarget.closest(".form-group").classList.add("field_with_errors")
      }
    }
  }

  displayProductName(name) {
    if (name) {
      this.productNameTarget.innerHTML = name
    } else {
      this.productNameTarget.innerHTML = "Produk belum dipilih"
    }
  }

  displayPicture(pictureUrl) {
    if (pictureUrl) {
      const imageElement = `<img src=${pictureUrl} class="new-order__product-image rounded mx-auto d-block">`
      this.productPictureTarget.innerHTML = imageElement
    } else {
      this.productPictureTarget.innerHTML = ''
    }
  }

  clearList(){
    const prospectiveItems = document.getElementById("prospective-items")

    prospectiveItems.innerHTML = `
      <div
        class="tw-bg-yellow-100 tw-border tw-border-yellow-300 tw-text-yellow-900
               tw-rounded p-3 tw-font-medium tw-flex tw-items-center"
      >
        <div class="tw-w-5 tw-mr-2">
          ${exclamationCircleSolid("tw-h-5 tw-w-5 tw-text-yellow-600")}
        </div>
        <span>Buat pencarian baru</span>
      </div>
    `
  }

  removeItemGroup(event) {
    event.preventDefault()
    const itemElement = event.currentTarget

    this.unbookItemGroup(itemElement.dataset.itemGroupId)
    itemElement.parentNode.classList.add("animate-flash-red")

    setTimeout(() => {
      itemElement.parentNode.parentNode.removeChild(itemElement.parentNode)
    }, 200);
  }

  unbookItemGroup(id) {
    this.bookedItemGroupIdsValue = this.bookedItemGroupIdsValue.filter(e => e !== id)
  }

  removeItem(event) {
    event.preventDefault()
    const itemElement = event.currentTarget

    this.unbookItem(itemElement.getAttribute("data-item-id"))

    itemElement.parentNode.classList.add("animate-flash-red")
    setTimeout(() => {
      itemElement.parentNode.parentNode.removeChild(itemElement.parentNode)
    }, 200);
  }

  unbookItem(id) {
    this.bookedIdsValue = this.bookedIdsValue.filter(e => e !== id)
  }

  errorMessage(msg) {
    return `
      <div
        class="tw-bg-red-100 tw-border tw-border-red-300 tw-text-red-900
               tw-rounded p-3 tw-font-medium tw-flex tw-items-center"
      >
        <div class="tw-w-5 tw-mr-2">
          ${exclamationCircleSolid("tw-h-5 tw-w-5 tw-text-red-600")}
        </div>
        <span>${msg}</span>
      </div>
    `
  }
}
